#App > #AppHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 0 16px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  #App > #AppHeader .LogoStory {
    background: radial-gradient(circle at 30% 107%, #1890ff 0%, #06dae0 60%);
    border-radius: 9px;
    color: white;
    text-transform: uppercase;
    padding: 4px 8px;
    font-weight: bold; }
  #App > #AppHeader .LogoText {
    color: #06dae0;
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px; }
  #App > #AppHeader ul.ant-menu {
    margin-left: 16px; }
  @media (max-width: 1000px) {
    #App > #AppHeader .ant-menu-item i.anticon {
      margin-right: 0; }
    #App > #AppHeader .ant-menu-item span.Text {
      display: none; }
    #App > #AppHeader .LogoText {
      display: none; }
    #App > #AppHeader .ant-btn > span {
      display: none; } }

#App > #AppContainer {
  padding-top: 48px; }
